function showAdapterModal() {
  const allButtons = document.querySelectorAll('.adapter-info');

  Array.from(allButtons).forEach((button) => {
    button.addEventListener('click', (e) => {
      e.currentTarget.parentElement.querySelector('.show-adapter-modal').classList.remove('d-none');
      document.body.style.overflow = 'hidden';
    });
  });
}

function closeAdapterModal() {
  const openModal = document.querySelectorAll('.show-adapter-modal');
  Array.from(openModal).forEach((modal) => {
    const closeButtons = modal.querySelectorAll(
      '.close-adapter-modal > i, .adapter-modal-footer > button, .adapter-modal-backdrop'
    );
    Array.from(closeButtons).forEach((closeBtn) => {
      closeBtn.addEventListener('click', (e) => {
        modal.classList.add('d-none');
        document.body.style.overflow = null;
      });
    });
  });
}

document.addEventListener('DOMContentLoaded', function () {
  showAdapterModal();
  closeAdapterModal();
});
